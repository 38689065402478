import backgroundImage1 from '../bgimg/image1.jpg';
import backgroundImage2 from '../bgimg/image2.jpg';
import backgroundImage3 from '../bgimg/image3.jpg';
import backgroundImage4 from '../bgimg/default.jpg';
import backgroundImage5 from '../bgimg/image4.jpg';
import backgroundImage6 from '../bgimg/image5.jpg';
import backgroundImage7 from '../bgimg/image6.jpg';
import backgroundImage8 from '../bgimg/image7.jpg';
import backgroundImage9 from '../bgimg/msdw/MSDW001.jpg';
import backgroundImage10 from '../bgimg/msdw/MSDW002.jpg';
import backgroundImage11 from '../bgimg/msdw/MSDW003.jpg';
import backgroundImage12 from '../bgimg/msdw/MSDW004.jpg';
import backgroundImage13 from '../bgimg/msdw/MSDW005.jpg';
import backgroundImage14 from '../bgimg/msdw/MSDW006.jpg';
import backgroundImage15 from '../bgimg/msdw/MSDW007.jpg';
import backgroundImage16 from '../bgimg/msdw/MSDW008.jpg';
import backgroundImage17 from '../bgimg/msdw/MSDW009.jpg';
import backgroundImage18 from '../bgimg/msdw/MSDW0010.jpg';
import backgroundImage19 from '../bgimg/msdw/MSDW0011.jpg';
import backgroundImage20 from '../bgimg/msdw/MSDW0012.jpg';
import backgroundImage21 from '../bgimg/msdw/MSDW0013.jpg';
import backgroundImage22 from '../bgimg/msdw/MSDW0014.jpg';
import backgroundImage23 from '../bgimg/msdw/MSDW0015.jpg';
import backgroundImage24 from '../bgimg/msdw/MSDW0016.jpg';
import backgroundImage25 from '../bgimg/msdw/MSDW0017.jpg';
import backgroundImage26 from '../bgimg/msdw/MSDW0018.jpg';
import backgroundImage27 from '../bgimg/msdw/MSDW0019.jpg';
import backgroundImage28 from '../bgimg/msdw/MSDW0020.jpg';
import backgroundImage29 from '../bgimg/msdw/MSDW0021.jpg';
import backgroundImage30 from '../bgimg/msdw/MSDW0022.jpg';
import backgroundImage31 from '../bgimg/msdw/MSDW0023.jpg';
import backgroundImage32 from '../bgimg/msdw/MSDW0024.jpg';
import backgroundImage33 from '../bgimg/msdw/MSDW0025.jpg';
import backgroundImage34 from '../bgimg/msdw/MSDW0026.jpg';
import backgroundImage35 from '../bgimg/msdw/MSDW0027.jpg';
import backgroundImage36 from '../bgimg/msdw/MSDW0028.jpg';
import backgroundImage37 from '../bgimg/msdw/MSDW0029.jpg';
import backgroundImage38 from '../bgimg/msdw/MSDW0030.jpg';
import backgroundImage39 from '../bgimg/msdw/MSDW0031.jpg';
import backgroundImage40 from '../bgimg/msdw/MSDW0032.jpg';
import backgroundImage41 from '../bgimg/msdw/MSDW0033.jpg';
import backgroundImage42 from '../bgimg/msdw/MSDW0034.jpg';
import backgroundImage43 from '../bgimg/msdw/MSDW0035.jpg';
import backgroundImage44 from '../bgimg/msdw/MSDW0036.jpg';
import backgroundImage45 from '../bgimg/msdw/MSDW0037.jpg';
import backgroundImage46 from '../bgimg/msdw/MSDW0038.jpg';
import backgroundImage47 from '../bgimg/msdw/MSDW0039.jpg';
import backgroundImage48 from '../bgimg/msdw/MSDW0040.jpg';
import backgroundImage49 from '../bgimg/msdw/MSDW0041.jpg';
import backgroundImage50 from '../bgimg/msdw/MSDW0042.jpg';
import backgroundImage51 from '../bgimg/msdw/MSDW0043.jpg';
import backgroundImage52 from '../bgimg/msdw/MSDW0044.jpg';
import backgroundImage53 from '../bgimg/msdw/MSDW0045.jpg';
import backgroundImage54 from '../bgimg/msdw/MSDW0046.jpg';
import backgroundImage55 from '../bgimg/msdw/MSDW0047.jpg';
import backgroundImage56 from '../bgimg/msdw/MSDW0048.jpg';
import backgroundImage57 from '../bgimg/msdw/MSDW0049.jpg';
import backgroundImage58 from '../bgimg/msdw/MSDW0050.jpg';
import backgroundImage59 from '../bgimg/msdw/MSDW0051.jpg';
import backgroundImage60 from '../bgimg/msdw/MSDW0052.jpg';
import backgroundImage61 from '../bgimg/msdw/MSDW0053.jpg';
import backgroundImage62 from '../bgimg/msdw/MSDW0054.jpg';
import backgroundImage63 from '../bgimg/msdw/MSDW0055.jpg';
import backgroundImage64 from '../bgimg/msdw/MSDW0056.jpg';
import backgroundImage65 from '../bgimg/msdw/MSDW0057.jpg';
import backgroundImage66 from '../bgimg/msdw/MSDW0058.jpg';
import backgroundImage67 from '../bgimg/msdw/MSDW0059.jpg';
import backgroundImage68 from '../bgimg/msdw/MSDW0060.jpg';
import backgroundImage69 from '../bgimg/msdw/MSDW0061.jpg';
import backgroundImage70 from '../bgimg/Spider-Man-spiderverse.jpg';



const backgroundImages = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage4,
  backgroundImage5,
  backgroundImage6,
  backgroundImage7,
  backgroundImage8,
  backgroundImage9,
  backgroundImage10,
  backgroundImage11,
  backgroundImage12,
  backgroundImage13,
  backgroundImage14,
  backgroundImage15,
  backgroundImage16,
  backgroundImage17,
  backgroundImage18,
  backgroundImage19,
  backgroundImage20,
  backgroundImage21,
  backgroundImage22,
  backgroundImage23,
  backgroundImage24,
  backgroundImage25,
  backgroundImage26,
  backgroundImage27,
  backgroundImage28,
  backgroundImage29,
  backgroundImage30,
  backgroundImage31,
  backgroundImage32,
  backgroundImage33,
  backgroundImage34,
  backgroundImage35,
  backgroundImage36,
  backgroundImage37,
  backgroundImage38,
  backgroundImage39,
  backgroundImage40,
  backgroundImage41,
  backgroundImage42,
  backgroundImage43,
  backgroundImage44,
  backgroundImage45,
  backgroundImage46,
  backgroundImage47,
  backgroundImage48,
  backgroundImage49,
  backgroundImage50,
  backgroundImage51,
  backgroundImage52,
  backgroundImage53,
  backgroundImage54,
  backgroundImage55,
  backgroundImage56,
  backgroundImage57,
  backgroundImage58,
  backgroundImage59,
  backgroundImage60,
  backgroundImage61,
  backgroundImage62,
  backgroundImage63,
  backgroundImage64,
  backgroundImage65,
  backgroundImage66,
  backgroundImage67,
  backgroundImage68,
  backgroundImage69,
  backgroundImage70,
];

export default backgroundImages;