const textLines = [
  // 'Nothing is certain except for death and taxes. - <strong>Benjamin Franklin</strong>',
  // 'The way to get started is to quit talking and begin doing. - <strong>Walt Disney</strong>',
  // 'Those who dare to fail miserably can achieve greatly. -<strong>John F. Kennedy</strong>',
  '懷疑人生就去散步🚶🏻 - <strong>香港散步學</strong>',
  '👋 Welcome, it\'s great to have you here.'
];

export default textLines;




