// src/data/content/武道狂之詩.js
import React from 'react';
import thumbnail1 from './images/thumbs/71sP6KdE1mL._SL1240_.jpg';

const page4 = {
id: 20240715,
title: '風從虎‧雲從龍',
thumbnail: thumbnail1,
pageTitle: (
<>
風從虎‧雲從龍
</>
),
content: (
<>

<h1>武道狂之詩 #1 - 風從虎‧雲從龍</h1>


<div class="intro">
    <p>劍風怒濤‧武鬥解禁‧狼派英雄出世</p>
    <p>「我認識不少真實的武者，他們練武，就是因為喜歡──喜歡把技藝練得圓熟的滿足感，喜歡將自我潛能推到極限的存在感。」──喬靖夫</p>
    <p>喬靖夫全新長篇系列《武道狂之詩》，從經典武俠原點再出發，以獨特視角，創造出具凌厲狠勁，強悍而不孤的濃厚「狼派」風味，令人血脈賁張！</p>
    <p>武俠解構。重裝上陣。</p>
    <p>『吾等奉掌門號令與爾比試，以宣示我武當派武學，天下無敵！』</p>
    <p>號稱「巴蜀無雙」、以超凡武學坐鎮四川的青城劍派，猶如貴族般為凡人尊崇，卻遇上了創派數百年來最大危機：強大的武當派遠征軍，打著『天下無敵，稱霸武林』的旗號，登山求戰！</p>
    <p>十七歲青城少年劍士燕橫，遭武當高手追殺，得身負異國武藝的奇男子荊裂相救。兩個同樣背負血仇的男人結成盟友，並肩踏上「討伐武當派」這條險惡漫長的道路。</p>
    <p>血與鋼鐵的征途，於焉展開！</p>
    <p>關於《武道狂之詩》的概念：</p>
    <p>摘自喬靖夫後記：</p>
    <p>「我是個武人。至少，曾經是。</p>
    <p>傳統的武俠小說世界裡，「武功」往往只是書中角色的能力甚至權力的一種具體象徵，武力不過是他們達成目的（例如私人恩仇、民族鬥爭、名利權勢）的工具或手段。</p>
    <p>我認識不少真實的武者，他們的想法可單純得多：練武，就是因為喜歡——喜歡把技藝練得圓熟的滿足感，喜歡將自我潛能推到極限的存在感。</p>
    <p>當然還有，追求那「最強」的夢想。</p>
    <p>說起來又像寫小說。但現實裡的確如此：所有真正下過苦功鍛鍊的武者，恐怕沒有一個不想像過自己要成為「最強」。即使只有很短促的念頭。即使到了最後，只有極少數的精英能夠堅持這條險隘的道路</p>
    <p>——世界冠軍，就是千萬個曾經夢想「最強」的人裡，最後淘汰剩下來那一個。</p>
    <p>這部書題為《武道狂之詩》，正是要描寫這種非常人的情懷。雖然貫穿全書的是「復仇」命題，但仇恨的肇因，仍然是追求「最強」的武者執念。</p>
    <p>故事的設定選擇了從最經典的武林門派世界出發，也是為了配合這個主題：在我心目中，武林，本來就應該是這樣的。</p>
    <p>回想起來其實有點慶幸，自己最初入行時，並沒有堅持寫武俠小說。否則恐怕很可能就墮入嚴重模仿某些前輩的道路。</p>
    <p>這些年來，寫了好些自成類型的東西，也算漸漸摸索到一點點個人的風格；現在繞一個圈子再回頭，才總算比較有信心，寫出「喬靖夫的武俠小說」來。</p>
    <p>——儘管，我仍然是站在「武俠傳統」這個偉大巨人的肩頭上寫。」</p>
    <p>推薦：</p>
    <p>小說之王九把刀，惺惺相惜，熱烈推介：</p>
    <p>「有些名字就是保證，例如……喬靖夫！」</p>
    <p>香港才子倪震：</p>
    <p>「喬靖夫的小說值得再三重看——而且第三遍才最好看！」</p>
    <p>金像導演陳嘉上：</p>
    <p>「喬靖夫是我們的新生代，思考獨立，古古怪怪又不乏視野，他的出現令我對香港本土流行文化創作再有期待！」</p>
    <p>熱血動作派導演葉偉信：</p>
    <p>「他筆下的動作影像，一讀就令人有要開拍的衝動！」</p>
    <p>香港漫畫達人利志達：</p>
    <p>「寫出罕有的連續動感，猶如用文字拍電影、畫漫畫！」</p>
    <p>香港動畫導演袁建滔：</p>
    <p>「寫盡漢子之間痛快淋漓的熱血對話，硬派武鬥，魅力非凡！」</p>
    <p>《非一般的武俠小說》特色：</p>
    <p>-以現代視角，重塑武俠小說，將運動科學，管治概念注入武俠小說之內。</p>
    <p>-高手不是藏在深山三十年就自然而成的！</p>
    <p>-內功不是特殊能量，其實武者一切身體操作，依靠的仍舊是肌肉筋骨產生之動能！</p>
    <p>-強盛的武林門派，必然有其嚴格的弟子層級的晉昇制度，從大量門生中逐步篩選精英，加以集中培養，如此方可保持該門派武功的傳承質素！</p>
    <p>-武道上有所謂「先天真力」，是真正武者的基本資質。它並非甚麼神秘力量，說穿了就是近代一般人口中的「運動神經」，在科學上也就是指人體的神經元傳導速度。</p>
    <p>-傳統武道之修練分為三等層次，分別為「氣」、「意」、「神」：「氣」者，即為先前所說的，「氣勁貫發」，講求身體肌肉的操作協調和神經的敏銳反應，純是肉體上的功夫；「意」，亦即腦袋和意念上的功夫。「神」，或曰「神妙」，不能傳授，可悟而不可求，乃是武道上口耳相傳的最高境地。</p>
    <p>-武鬥的世界是高速的世界；因此傳統武道漸漸出現一套對微細時間的計量概念，其中各單位，古人以人體的脈搏跳動，以計算短促時間。「分」、「秒」、「毫」、「忽」「曜炫」。「曜炫」乃是指稀微的星光，若隱若現的一閃。武道上有「曜炫之劍」一詞，象徵了最快的神妙境界。</p>
    <p>以明朝為背景，突破以中土為中心的傳統武俠故事，滲入大量當時的異國武術知識：</p>
    <p>-例如鹿兒島薩州（薩摩國）武士，以粗獷的實戰劍法示現流（又稱自顯流）聞名於世。主角之一荊裂在薩摩接觸並學得的日本刀法，主要實為「陰流」劍術。（日本的「劍術」，其實是砍斬為主的單刃刀法。）大明抗擊倭寇的名將戚繼光，著書記錄其所得日本刀法（辛酉刀法），當中有記載《影流之目錄》刀譜。</p>
    <p>-荊裂所到達的暹羅為大城（阿育陀耶）王國，當時暹羅武士所受的武術訓練，稱Krabi Krabong，乃是集合刀術、長矛、拳法等多種項目的戰場武術，其技法深受天竺（印度）武藝之影響。當中徒手拳法一項，即是現代世界知名的「八臂武術」——「泰拳」之始祖。</p>
    <p>-荊裂又於蘇祿群島，跟當地回教徒學習刀法。菲律賓南部的穆斯林民族稱作「摩洛人」（Moro），其血統與信仰乃從馬來群島傳來，武術風格亦是深受馬來武術Silat的影響。因當地人身材及生活習性影響，摩洛人武術的主力技法是刀劍短兵。數百年來，摩洛人不斷以武力手段對抗西班牙殖民者、美國佔領者以至今日的費律賓政府，可見其民風之強悍。</p>
</div>

</>
),

customContent: (
	<div className="custom-content">

	        目錄：<br />
	        《武道狂之詩》Sangre y acero<br />
	        第一卷 風從虎‧雲從龍<br />
	        序章 颶風男兒 第一章 五里亭武鬥 第二章 青城劍派 第三章 道傳弟子<br />
	        第四章 武當眾<br />
	        第五章 坎離水火‧雌雄龍虎 第六章 異刀客<br />
	        第七章 歸國的獵人<br />
	        第八章 決志<br />
	</div>
),

slug: '武道狂之詩',
categories: ['狼派小說', '卷一', 'Fiction', '文學'],
tags: ['Tag 2', 'Tag 4', 'Tag 5'],
date: '2024-07-15',
showCustomField: true,
};

export default page4;
