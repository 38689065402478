import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BookmarkCollection = ({ collectionId }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBookmarks = async () => {
      try {
        // Adding `per_page=50` to request 50 items
        const response = await axios.get(`https://api.raindrop.io/rest/v1/raindrops/${collectionId}?perpage=50`, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_RAINDROP_API_TOKEN}`
          }
        });
        setBookmarks(response.data.items);
      } catch (err) {
        setError(`Failed to fetch bookmarks: ${err.response?.statusText || err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookmarks();
  }, [collectionId]);

  if (isLoading) return <div role="status">Loading...</div>;
  if (error) return <div role="alert">Error: {error}</div>;

  return (
    <div>
      <h2>Bookmarks</h2>
      <ol>
        {bookmarks.map(bookmark => (
          <li key={bookmark._id}>
            <a href={bookmark.link} target="_blank" rel="noopener noreferrer" aria-label={`Open bookmark titled ${bookmark.title}`}>
              {bookmark.title}
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default BookmarkCollection;