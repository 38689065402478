// src/views/Logs.js
import React from 'react';
import BookmarkCollection from '../components/BookmarkCollection'; // Adjust the import path as necessary
const Logs = () => {


return (
<>
<main>
  <div id="backgroundGrid">
  <div className="fullscreenGrid center">

  <div className="shows-container whitebg tnc" style={{height: '80%', overflowX: 'auto'}}>

  <h2>Terms of Service</h2>

  <p><strong>Last updated: 11 July 2023</strong></p>


  <div className="BookmarkCollectionContainer">
    <BookmarkCollection collectionId="28384348" />
    </div>

  </div>
  </div>
  </div>
</main>

</>
);
};

export default Logs;