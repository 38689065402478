// src/data/pageData.js
import page1 from './content/page-1-一個毫無道德土壤的國家';
import page2 from './content/page-2';
import page3 from './content/page-3';
import page4 from './content/page-4-探究法國巴黎咖啡館——藝文思潮起源地';
import page5 from './content/page-5-武道狂之詩';

const pageData = [
	page1,
	page2,
	page3,
	page4,
	page5,
];

export default pageData;