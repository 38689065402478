// src/data/content/探究法國巴黎咖啡館——藝文思潮起源地.js
import React from 'react';
import thumbnail1 from './images/thumbs/avstorm-FzseCzuX0AE4q0K.jpg';

const page4 = {
  id: 20240430,
  title: '探究法國巴黎咖啡館——藝文思潮起源地',
  thumbnail: thumbnail1,
  pageTitle: (
    <>
      探究法國巴黎咖啡館——藝文思潮起源地
    </>
  ),
  content: (
    <>
      法國咖啡館自17世紀開始，已成為當地人民生活中不可或缺的一部份；據統計2020年法國的咖啡館數量約為1.5萬間，零售總額超過16億歐元，佔市場總收入超過八成。最最最重要的是，這是文學及藝術思潮的原點，是醞釀文化的重要土壤。



      <img src="https://www.milk.com.hk/content/images/size/w1600/2024/04/photo1.jpeg" width="30%" alt="" />

      原文網址： <a href="https://www.milk.com.hk/france-cafe/">值得列入文化遺產的法國巴黎咖啡館！探究藝文思潮起源地</a>.
    </>
  ),
  customContent: (
    <div className="custom-content">
<p>早年，法國酒館與咖啡館協會（Bistrots et Cafés de France）向UNESCO爭取將法國咖啡館列為非物質文化遺產。法國咖啡館到底有甚麼本事能要求列入文化遺產？</p>

<p>法國咖啡館自17世紀開始，已成為當地人民生活中不可或缺的一部份；據統計2020年法國的咖啡館數量約為1.5萬間，零售總額超過16億歐元，佔市場總收入超過八成。最最最重要的是，這是文學及藝術思潮的原點，是醞釀文化的重要土壤。</p>

<p>法國若然沒有咖啡館，當年沙特（Jean Paul Sartre）跟西蒙波娃（Simone de Beauvoir）不會相戀，今天我們也不會看到馬奈（Édouard Manet）的《The Absinthe Drinker》，亦不會讀得到海明威（Ernest Hemingway）的回憶錄《流動的饗宴（A Moveable Feast》。</p>
    </div>
  ),
  slug: '探究法國巴黎咖啡館——藝文思潮起源地',
  categories: ['文化', '法國', '咖啡', '文學'],
  tags: ['Tag 2', 'Tag 4', 'Tag 5'],
  date: '2024-04-30',
  showCustomField: true,
};

export default page4;
