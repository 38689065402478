// src/data/content/一個毫無道德土壤的國家.js
import React from 'react';
import thumbnail1 from './images/thumbs/avstorm-FzseCzuX0AE4q0K.jpg';

const page1 = {
  id: 1,
  title: '一個毫無道德土壤的國家',
  thumbnail: thumbnail1,
  pageTitle: (
    <>
      一個毫無道德土壤的國家
    </>
  ),
  content: (
    <>
      而「背叛」（Betrayel），在一個毫無道德土壤的國家，在被列寧和史太林曾經統治的國度，就有如漢堡包對於美國人，只是平時生存的基本概念。

      <img src="https://pbs.twimg.com/media/Fzt8-D8XwAQGMpr?format=jpg&name=medium" width="20%" alt="" />

      原文網址： <a href="https://www.cup.com.hk/">https://www.cup.com.hk/</a>.
    </>
  ),
  customContent: (
    <div className="custom-content">
      This is some custom content for Page 1.
    </div>
  ),
  slug: '一個毫無道德土壤的國家',
  categories: ['國家', 'Category 1', 'Category 3', 'Alpha Tauri'],
  tags: ['Tag 2', 'Tag 4', 'Tag 5'],
  date: '2023-06-28',
  showCustomField: true,
};

export default page1;
