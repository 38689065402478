// src/components/TabNavigator.js
import React from 'react';
import WebsiteCards from './WebsiteCards';

const TabNavigator = ({ activeTab, handleTabClick, dailyWebsites, magazineWebsites, devWebsites, workWebsites }) => {
    return (
        <>
            <div className="tabNavigation">

	            <div className="google">
		            <a href="https://www.google.com.hk/" target="_blank" rel="noopener noreferrer">
		                <span className="g">G</span>
		                <span className="o">o</span>
		                <span className="oo">o</span>
		                <span className="g">g</span>
		                <span className="l">l</span>
		                <span className="e">e</span>
		            </a>
	            </div>

                <div className={`tab ${activeTab === 'dailyContainer' ? 'activeTab' : ''}`} onClick={() => handleTabClick('dailyContainer')}>
                    Daily
                </div>
                <div className={`tab ${activeTab === 'magazineContainer' ? 'activeTab' : ''}`} onClick={() => handleTabClick('magazineContainer')}>
                    Magazine
                </div>
                <div className={`tab ${activeTab === 'devWebsites' ? 'activeTab' : ''}`} onClick={() => handleTabClick('devWebsites')}>
                    Dev
                </div>
                <div className={`tab ${activeTab === 'workContainer' ? 'activeTab' : ''}`} onClick={() => handleTabClick('workContainer')}>
                    Work
                </div>

            </div> {/* END tabNavigation */}

            <div className={`dailyContainer ${activeTab === 'dailyContainer' ? 'active' : 'hidden'}`}>
                <WebsiteCards websites={dailyWebsites} />
            </div>
            <div className={`magazineContainer ${activeTab === 'magazineContainer' ? 'active' : 'hidden'}`}>
                <WebsiteCards websites={magazineWebsites} />
            </div>
            <div className={`devWebsites ${activeTab === 'devWebsites' ? 'active' : 'hidden'}`}>
                <WebsiteCards websites={devWebsites} />
            </div>
            <div className={`workContainer ${activeTab === 'workContainer' ? 'active' : 'hidden'}`}>
                <WebsiteCards websites={workWebsites} />
            </div>
        </>
    );
};

export default TabNavigator;